<template>
  <div class="click-img-to-show-sentence-game layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNO">
      <Star
        :totalStarNumber="optionList.length"
        :currentIndex="currentStarIndex"
      />
      <div
        class="title-area"
        v-if="titleInfo"
        :class="[
          titleInfo.size == 'small' ? `small-title` : '',
          'lesson-' + lessonNO,
          titleInfo.position == 'right' ? 'isRightSide' : ''
        ]"
      >
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="background-area">
        <img :src="bgImg" />

        <template v-for="(item, index) in optionList">
          <transition name="el-fade-in" :key="index + 'bg'">
            <img
              :src="item.isShowCorrect ? item.clickImg : item.bgImg"
              class="bg-img-item"
              :class="{
                fistImgOpacity:
                  (!item.isShowCorrect && noFirstImg) ||
                  (item.isShowCorrect && !noFirstImg),
              }"
            />
          </transition>
        </template>
      </div>

      <div class="click-area">
        <div
          v-for="(item, index) in optionList"
          :key="index + 'click'"
          :class="'click-area-' + item.id"
          @click="handleClickImg(item.id)"
        />
      </div>

      <div v-if="isShowMask" @click="handleShowSentence" class="mask-area">
        <div
          class="mask-item"
          v-for="(item, index) in maskImgList"
          :key="index + 'mask'"
          v-show="currentId === item.id"
        >
          <div class="mask-img">
            <img :src="item.img" alt="" v-if="item.img" />
          </div>

          <div class="sentence" v-if="item.sentenceHanzi && isShowSentence">
            <p class="pinyin font-pinyin-medium">{{ item.centencePinyin }}</p>
            <p class="font-hanzi-medium">{{ item.sentenceHanzi }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "ClickImgToShowSentenceGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: true,
    },
    optionList: {
      type: Array,
      require: true,
    },
    maskImgList: {
      type: Array,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    noFirstImg: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      idList: [],
      currentId: 0,
      currentStarIndex: 0,
      isShowMask: false,
      isShowSentence: false,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },

      idSocketInfo: {},
      clickSocketInfo: {},
    };
  },
  components: {
    Star,
    PageButton,
  },
  watch: {
    idSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006001,
          data: { value },
          text: "SpeakingSentence点击Menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006002,
          data: { value },
          text: "SpeakingSentence点击Img socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("getIdSocket", ({ id }) => {
      this.handleClickImg(id, true);
    });
    this.$bus.$on("showSentenceSocket", () => {
      this.handleShowSentence("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("getIdSocket");
    this.$bus.$off("showSentenceSocket");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.idSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      this.currentId = id;
      playCorrectSound(true, false);
      console.log("点击选中区域");

      this.isShowMask = true;
      console.log(this.idList.indexOf(this.currentId));
      if (this.idList.indexOf(this.currentId) === -1) {
        this.idList.push(this.currentId);    
        this.$emit("showCoeerctImg", this.currentId);
        console.log(this.idList)
      }
    },
    handleShowSentence(socket, isFromSocket = false) {
      console.log("点击背景图", this.isShowSentence);
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random(),
        };
      }
      if (this.isShowSentence) {
        console.log("第二次点击");

        this.isShowSentence = false;
        this.isShowMask = false;
      } else {
        this.isShowSentence = true;
        this.currentStarIndex = this.idList.length;
        if (this.currentStarIndex === this.optionList.length) {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound();
        } else {
          playCorrectSound(true, false);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.click-img-to-show-sentence-game {
  background: #fff;
  border-radius: 48px;
  .game-content {
    width: 100%;
    height: 100%;
    // background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
      }
      .fistImgOpacity {
        opacity: 0;
      }
    }
    .click-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //  > div { background: red; opacity: 0.5;}
      .click-area-1 {
        display: block;
        position: absolute;
        width: 23%;
        height: 37%;
        top: 7%;
        left: 9%;
        z-index: 5;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-2 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 19%;
        height: 15%;
        top: 56%;
        left: 5%;
        &:hover {
          cursor: pointer;
        }
      }

      .click-area-3 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 8%;
        height: 12%;
        top: 40%;
        left: 52%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-4 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-5 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-6 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .title-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 30%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      &.isRightSide {
        left: auto;
        right: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      &.small-title {
        width: 20% !important;
      }
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .lesson-44 {
      width: 20%;
    }
    .lesson-45 {
      width: 20%;
    }
    .mask-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.1);
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .mask-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .mask-img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            border-radius: 58px;
          }
        }
        .sentence {
          position: absolute;
          // width: 70%;
          text-align: center;
          background: #cd4c3f;
          padding: 15px 6%;
          color: #fff;
          border-radius: 20px;
          bottom: 8%;
          margin: auto 0;
        }
        .showSentence {
          opacity: 1;
        }
      }
    }
  }
  .lesson-18 {
    .click-area {
      .click-area-1 {
        width: 5%;
        height: 7%;
        top: 45%;
        left: 69%;
        // background: #000;
      }
      .click-area-2 {
        width: 6%;
        height: 7%;
        top: 42%;
        left: 22%;
        // background: #000;
      }

      .click-area-3 {
        width: 5%;
        height: 8%;
        top: 32%;
        left: 25%;
        // background: #000;
      }
    }
  }
  .lesson-21 {
    .click-area {
      .click-area-1 {
        width: 24%;
        height: 26%;
        top: 70%;
        left: 64%;
      }
      .click-area-2 {
        width: 15%;
        height: 16%;
        top: 46%;
        left: 73%;
      }

      .click-area-3 {
        width: 18%;
        height: 28%;
        top: 58%;
        left: 17%;
      }
      .click-area-4 {
        width: 14%;
        height: 50%;
        top: 33%;
        left: 36%;
      }
      .click-area-5 {
        width: 12%;
        height: 35%;
        top: 48%;
        left: 50%;
      }
      .click-area-6 {
        width: 7%;
        height: 12%;
        top: 56%;
        left: 50%;
      }
    }
  }
  .lesson-20 {
    .click-area {
      .click-area-1 {
        width: 14%;
        height: 26%;
        top: 27%;
        left: 42%;
      }
      .click-area-2 {
        width: 15%;
        height: 24%;
        top: 6%;
        left: 60%;
      }

      .click-area-3 {
        width: 12%;
        height: 25%;
        top: 21%;
        left: 77%;
      }
      .click-area-4 {
        width: 14%;
        height: 26%;
        top: 37%;
        left: 60%;
      }
    }
  }
  .lesson-26 {
    .click-area {
      .click-area-1 {
        width: 18%;
        height: 13%;
        top: 60%;
        left: 5%;
      }
      .click-area-2 {
        width: 21%;
        height: 42%;
        top: 0%;
        left: 10%;
      }

      .click-area-3 {
        width: 7%;
        height: 13%;
        top: 39%;
        left: 53%;
      }
      .click-area-4 {
        width: 22%;
        height: 10%;
        top: 25%;
        left: 73%;
      }
    }
  }
  .lesson-29 {
    .click-area {
      .click-area-1 {
        width: 7%;
        height: 14%;
        top: 0%;
        left: 18.5%;
        // background: #000;
        // opacity:0.5
      }
      .click-area-2 {
        width: 7%;
        height: 14%;
        top: 33%;
        left: 40%;
        // background: #000;
        // opacity:0.5
      }

      .click-area-3 {
        width: 7%;
        height: 14%;
        top: 0%;
        left: 56%;
        // background: #000;
        // opacity:0.5
      }
      .click-area-4 {
        width: 7%;
        height: 14%;
        top: 31%;
        left: 74%;
        // background: #000;
        // opacity:0.5
      }
    }
  }
  .lesson-292 {
    .click-area {
      .click-area-1 {
        width: 18%;
        height: 13%;
        top: 15%;
        left: 33%;
      }
      .click-area-2 {
        width: 30%;
        height: 34%;
        top: 1%;
        left: 64%;
      }

      .click-area-3 {
        width: 31%;
        height: 24%;
        top: 63%;
        left: 46%;
      }
      .click-area-4 {
        width: 20%;
        height: 28%;
        top: 32%;
        left: 16%;
      }
    }
  }

  .lesson-371 {
    .click-area {
      .click-area-1 {
        // background: #000;
        width: 13%;
        height: 21%;
        top: 24%;
        left: 13%;
      }
      .click-area-2 {
        // background: #000;
        width: 13%;
        height: 22%;
        top: 24%;
        left: 28%;
      }

      .click-area-3 {
        // background: #000;
        width: 13%;
        height: 21%;
        top: 24%;
        left: 43%;
      }
      .click-area-4 {
        // background: #000;
        width: 10%;
        height: 15%;
        top: 57%;
        left: 14%;
      }
      .click-area-5 {
        // background: #000;
        width: 24%;
        height: 12%;
        top: 61%;
        left: 30%;
      }
    }
  }

  .lesson-372 {
    .click-area {
      .click-area-1 {
        width: 13%;
        height: 30%;
        top: 45%;
        left: 11%;
        // background: #000;
      }
      .click-area-2 {
        width: 17%;
        height: 13%;
        top: 44%;
        left: 28%;
        // background: #000;
      }

      .click-area-3 {
        width: 17%;
        height: 13%;
        top: 61%;
        left: 28%;
        // background: #000;
      }
      .click-area-4 {
        width: 14%;
        height: 28%;
        top: 45%;
        left: 49%;
        // background: #000;
      }
    }
  }

  .lesson-42 {
    .click-area {
      .click-area-1 {
        width: 13%;
        height: 17%;
        top: 74%;
        left: 7%;
      }
      .click-area-2 {
        width: 10%;
        height: 13%;
        top: 51%;
        left: 18%;
      }

      .click-area-3 {
        width: 7%;
        height: 33%;
        top: 52%;
        left: 42%;
      }
      .click-area-4 {
        width: 18%;
        height: 11%;
        top: 43%;
        left: 62%;
      }
    }
  }

  .lesson-44 {
    .click-area {
      .click-area-1 {
        width: 16%;
        height: 15%;
        top: 28%;
        left: 16%;
      }
      .click-area-2 {
        width: 10%;
        height: 20%;
        top: 8%;
        left: 53%;
      }

      .click-area-3 {
        width: 9%;
        height: 12%;
        top: 21%;
        left: 76%;
      }
      .click-area-4 {
        width: 9%;
        height: 32%;
        top: 41%;
        left: 65%;
      }
      .click-area-5 {
        width: 8%;
        height: 16%;
        top: 64%;
        left: 38%;
      }
    }
  }

  .lesson-45 {
    .click-area {
      .click-area-1 {
        width: 24%;
        height: 23%;
        top: 70%;
        left: 10%;
      }
      .click-area-2 {
        width: 17%;
        height: 13%;
        top: 28%;
        left: 27%;
      }

      .click-area-3 {
        width: 16%;
        height: 20%;
        top: 48%;
        left: 38%;
      }
      .click-area-4 {
        width: 16%;
        height: 12%;
        top: 25%;
        left: 69%;
      }
    }
  }

  .lesson-56 {
    .click-area {
      .click-area-1 {
        top: 0% !important;
        height: 25% !important;
      }
      .click-area-2 {
        height: 20% !important;
      }
      .click-area-3 {
        bottom: 0% !important;
        top: auto;
        left: 55% !important;
        height: 15% !important;
        width: 15% !important;
      }
      .click-area-4 {
        height: 25% !important;
      }
    }
  }

  .lesson-61 {
    .click-area {
      .click-area-1 {
        // background:blue;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 58%;
        left: 72.5%;
      }
      .click-area-2 {
        // background:blue;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 34%;
        left: 17%;
      }

      .click-area-3 {
        // background:blue;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 47%;
        left: 63%;
      }
      .click-area-4 {
        // background:blue;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 58%;
        left: 35.5%;
      }
    }
  }
  .lesson-62 {
    .click-area {
      .click-area-1 {
        // background:blue;
        // opacity:0.5;
        width: 16%;
        height: 21%;
        top: 8%;
        left: 58%;
      }
      .click-area-2 {
        // background:blue;
        // opacity:0.5;
        width: 13%;
        height: 16%;
        top: 80%;
        left: 28%;
      }

      .click-area-3 {
        // background:blue;
        // opacity:0.5;
        width: 10%;
        height: 13%;
        top: 47%;
        left: 80%;
      }
      .click-area-4 {
        // background:blue;
        // opacity:0.5;
        width: 6%;
        height: 13%;
        top: 10%;
        left: 21%;
      }
    }
  }
  .lesson-64 {
    .click-area {
      .click-area-1 {
        // background:blue;
        // opacity:0.5;
        width: 12%;
        height: 10%;
        top: 57%;
        left: 50%;
      }
      .click-area-2 {
        // background:blue;
        // opacity:0.5;
        width: 13%;
        height: 18%;
        top: 24%;
        left: 71%;
      }

      .click-area-3 {
        // background:blue;
        // opacity:0.5;
        width: 25%;
        height: 32%;
        top: 24%;
        left: 19%;
      }
      .click-area-4 {
        // background:blue;
        // opacity:0.5;
        width: 5%;
        height: 11%;
        top: 60%;
        left: 23%;
      }
    }
  }
}
</style>
